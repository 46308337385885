/* purgecss start ignore */

.embla {
  --slide-spacing: 2rem;
  --slide-size: 100%;
  --slide-height: 31rem;
  padding: 1.6rem;
}
.embla__viewport {
  overflow: hidden;
}
.embla__container {
  display: flex;
  flex-direction: row;
  height: auto;
}
.embla__slide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
  position: relative;
}

@media (max-width: 949px) {
  .embla__slide {
    @apply px-0 mx-4;
  }
}

.embla__slide_featured {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: 2rem;
  position: relative;
  @apply px-0;
}

@media (max-width: 949px) {
  .embla__slide_featured {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

.embla__slide:nth-child(2):before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: -1px;
  bottom: 0;
  width: 2px;
  background-color: #f9fafb;
}
.embla__slide__img {
  display: block;
  height: var(--slide-height);
  width: 100%;
  object-fit: cover;
}
.embla__slide__number {
  width: 4.6rem;
  height: 4.6rem;
  z-index: 1;
  position: absolute;
  top: 0.6rem;
  right: 0.6rem;
  border-radius: 50%;
  background-color: rgba(var(--background-site-rgb-value), 0.85);
  line-height: 4.6rem;
  font-weight: 900;
  text-align: center;
  pointer-events: none;
}
.embla__slide__number > span {
  color: var(--brand-primary);
  background-image: linear-gradient(
    45deg,
    var(--brand-primary),
    var(--brand-secondary)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.6rem;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@media (max-width: 768px) {
  .embla {
    padding: 0;
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
  }
}
/* purgecss end ignore */
